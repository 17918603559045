import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import tachyons from 'styled-components-tachyons';

const Image = styled.img`
  vertical-align: middle;
  width: ${props => props.width}px;
  height: ${props => props.height ? props.height + 'px' : ''};
  ${tachyons};

  ${props =>{
    
    return Array.isArray(props.width) &&
    css`
      width: ${`${props.width[0]}px`};
      height: ${props => props.height[0] ? props.height[0] + 'px' : ''};

      @media (min-width: 450px) and (max-width: 799px) {
        width: ${`${props.width[1] || props.width[0]}px`};
        height: ${props => props.height[1] ? props.height[1] + 'px' : ''};
      }

      @media (min-width: 800px) {
        width: ${`${props.width[2] || props.width[1] || props.width[0]}px`};
        height: ${props => props.height[2] ? props.height[2] + 'px' : ''};
      }
    `}};
`;

const StyledImage = props => {
  return (
    <Image
      src={props.src}
      height={props.height}
      width={props.width}
      {...props}
    />
  );
};

StyledImage.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};

export default StyledImage;
