import React, { Component } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled from 'styled-components'
import Button from '../Button'
import Icon from '../Icon'
import Type from '../Type'
import { icon } from '../../global/constants'

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(62, 72, 82, 0.1);
  z-index: 9999;
  overflow: scroll;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
`

const ModalWrapper = styled.div`
  background-color: white;
  display: block;
  vertical-align: middle;
  margin: auto;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '480px')};
  padding: 20px;
  margin-top: 10px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.05),
    0 0px 50px 0px rgba(0, 0, 0, 0.1);
`

const Header = styled.header`
  margin-bottom: 20px;
  text-align: center;
`

const Close = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;

  @media screen and (min-width: 500px) {
    position: absolute;
  }
`

class Modal extends Component {
  componentDidMount() {
    this.targetElement = document.querySelector('#targetElementId')
    if (this.props.isActive) {
      disableBodyScroll(this.targetElement)
    }
  }
  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }
  render() {
    const {
      children,
      isActive,
      title,
      closeModal,
      maxWidth,
      ...props
    } = this.props

    return isActive ? (
      <Mask>
        <div>
          <ModalWrapper
            role="dialog"
            tabIndex="0"
            maxWidth={maxWidth}
            {...props}
          >
            <Header>
              <Type variant="h1" f3 lh_copy fw6 dark_gray mt0>
                {title}
              </Type>
              <Close>
                <Button
                  onClick={closeModal}
                  aria-label="close"
                  link
                  pa2
                  lh_solid
                >
                  <Icon type={icon.close} />
                </Button>
              </Close>
            </Header>

            <div>{children}</div>
          </ModalWrapper>
        </div>
      </Mask>
    ) : null
  }
}

export default Modal
